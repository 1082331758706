import React, { Fragment, useEffect, useState } from "react"
import { Link } from "gatsby"
import { interpolateString } from "../../helpers/Strings"
import { useQueryParam, StringParam } from "use-query-params"
import { Routes, navigate } from "../../data/routes"
import {
  Plans,
  PlanType,
  StorageField,
  PaymentOptions,
  PurchaseData as FormData,
} from "../../data/plans"
import { SubmitButton } from "../../components/button"
import Card from "../../components/card"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Dialog, Transition } from "@headlessui/react"
import { LockClosedIcon, PencilIcon, XIcon } from "@heroicons/react/outline"

const Content = {
  pageName: "Review",
  header: "Review your plan",
  description: `After confirming your plan details, click
    'Start Plan' to join the Yup family!`,

  // Plan details
  startLabel: "Plan starts",
  trialEndLabel: "Trial ends",
  endLabel: "Plan ends",
  planMonthlyCost: "{{durationString}} at ${{monthlyPrice}}/mo",
  totalCost: "You pay",

  // Student details
  studentDetailsHeader: "Student Account Details",
  studentFirstNameLabel: "Student's First Name",
  studentPhoneLabel: "Student's Mobile Number",

  // Payer details
  payerDetailsHeader: "Payer Account Details",
  payerNameLabel: "Payer's Name",
  payerEmailLabel: "Payer's Email Address",
  payerPhoneLabel: "Payer's Mobile Number",

  // Payment details
  paymentDetailsHeader: "Payment Details",
  referralCodeLabel: "Referral code",
  paymentMethodLabel: "Payment Method",
  billingLabel: "Billing Address",

  // Checkout
  privacyDisclaimer: `We take privacy very seriously and never
    share or sell Student or Payer details.`,
  submit: "Start plan",
  paymentFailHeader: "Payment Unsuccessful",
  defaultError: "There was an error completing your purchase. Please try again",
  paymentFailAction: "Okay",
}

function Header() {
  return (
    <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
          {Content.header}
        </p>
        <p className="max-w-5xl mt-5 mx-auto text-xl flex text-gray-500 justify-center">
          {Content.description}
        </p>
      </div>
    </div>
  )
}

function EditLink(props: { to: string }) {
  return (
    <Link to={props.to} className="mx-4 inline-block align-text-top">
      <PencilIcon className="text-blue h-5 w-5" />
    </Link>
  )
}

type TableItem = {
  label: string
  value: string | number
}

type TableProps = {
  header: string
  editLink: string
  items: Array<TableItem>
  additional?: React.ReactNode
}

function Table(props: TableProps) {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/data-display/description-lists#component-e1b5917b21bbe76a73a96c5ca876225f
  return (
    <div className="max-w-4xl mx-auto">
      <Card>
        <div className="pb-5 pt-1 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {props.header}
            <EditLink to={props.editLink} />
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            {props.items.map(item => (
              <div
                key={item.label}
                className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                <dt className="text-sm font-medium text-gray-500">
                  {item.label}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 sm:text-right">
                  {String(item.value)
                    .split("\n")
                    .map(val => (
                      <p key={val}>{val}</p>
                    ))}
                </dd>
              </div>
            ))}
          </dl>
          {props.additional}
        </div>
      </Card>
    </div>
  )
}

function PlanDetails(props: { plan: PlanType; editLink: string }) {
  const { trial_eligible, title, durationString, price, monthlyPrice } =
    props.plan

  const today = new Date()
  const trialEnd = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + props.plan.trial_days
  )
  const planEnd = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + props.plan.duration_days
  )

  function formatDate(date: Date) {
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  const items = [
    {
      label: Content.startLabel,
      value: formatDate(today),
    },
    {
      label: Content.trialEndLabel,
      value: trial_eligible ? formatDate(trialEnd) : "N/A",
    },
    {
      label: Content.endLabel,
      value: formatDate(planEnd),
    },
  ]

  return (
    <Table
      header={title}
      editLink={props.editLink}
      items={items}
      additional={
        <>
          <hr />
          <div className="px-4 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-end">
            <dt className="font-medium text-gray-500">
              <p className="text-sm">
                {interpolateString(Content.planMonthlyCost, {
                  durationString,
                  monthlyPrice,
                })}
              </p>
              <p className="font-bold mt-1 text-lg">{Content.totalCost}</p>
            </dt>
            <dd className="text-gray-900 sm:mt-0 sm:col-span-2 font-bold text-xl sm:text-right">
              ${price.toLocaleString()}
            </dd>
          </div>
        </>
      }
    />
  )
}

function StudentAccountDetails(props: { data: FormData; editLink: string }) {
  const items = [
    {
      label: Content.studentFirstNameLabel,
      value: props.data.studentName,
    },
    {
      label: Content.studentPhoneLabel,
      value: props.data.studentPhone,
    },
  ]
  return (
    <Table
      header={Content.studentDetailsHeader}
      editLink={props.editLink}
      items={items}
    />
  )
}

function PayerAccountDetails(props: { data: FormData; editLink: string }) {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/data-display/description-lists#component-e1b5917b21bbe76a73a96c5ca876225f
  const items = [
    {
      label: Content.payerNameLabel,
      value: `${props.data.payerFirstName} ${props.data.payerLastName}`,
    },
    {
      label: Content.payerEmailLabel,
      value: props.data.payerEmail,
    },
    {
      label: Content.payerPhoneLabel,
      value: props.data.payerPhone,
    },
  ]
  return (
    <Table
      header={Content.payerDetailsHeader}
      editLink={props.editLink}
      items={items}
    />
  )
}

function PaymentDetails(props: { data: FormData; editLink: string }) {
  let paymentMethod
  switch (props.data.paymentType) {
    case PaymentOptions.credit:
      paymentMethod = `****-${props.data.paymentInfo.credit_card.lastFour}`
      break
    default:
      paymentMethod = PaymentOptions.paypal
  }

  const items = [
    {
      label: Content.referralCodeLabel,
      value: props.data.referralCode || "N/A",
    },
    {
      label: Content.paymentMethodLabel,
      value: paymentMethod,
    },
    {
      label: Content.billingLabel,
      value: `${props.data.cardName ?? ""}
        ${props.data.billing1}
        ${props.data.billing2 ?? ""}
        ${props.data.city}, ${props.data.state} ${props.data.zipCode}`,
    },
  ]
  return (
    <Table
      header={Content.paymentDetailsHeader}
      editLink={props.editLink}
      items={items}
    />
  )
}

function StartPlanSection(props: { onSubmit: () => void }) {
  function onSubmit(event: React.FormEvent) {
    event.preventDefault()
    props.onSubmit()
  }

  return (
    <form
      onClick={onSubmit}
      className="text-center sm:text-left pb-16 max-w-4xl mx-auto px-8"
    >
      <SubmitButton text={Content.submit} />
      <div className="mt-8">
        <LockClosedIcon className="h-6 w-6 inline-block mr-2" />
        <p className="align-middle inline-block">{Content.privacyDisclaimer}</p>
      </div>
    </form>
  )
}

function PlanSubmissionFail(props: { message: string; onDismiss: () => void }) {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/overlays/modals#component-31555aec01de5027d6696629eb60f673

  return (
    <Transition.Root show={Boolean(props.message)} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={Boolean(props.message)}
        onClose={props.onDismiss}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center">
                  <XIcon className="h-6 w-6 text-red" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {Content.paymentFailHeader}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{props.message}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 text-center">
                <button
                  onClick={props.onDismiss}
                  className="inline-flex justify-center px-5 py-2 rounded-full shadow-sm font-display uppercase text-base text-center hover:shadow-md focus:outline-none m-2 border-transparent bg-secondary-500 text-white hover:bg-secondary-600"
                >
                  {Content.paymentFailAction}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const ReviewPurchase = () => {
  const [sku] = useQueryParam("sku", StringParam)
  const [plan, setPlan] = useState<PlanType>()
  const [errorMessage, setErrorMessage] = useState("")

  if (typeof sessionStorage === "undefined") return null

  useEffect(() => {
    ;(async () => {
      try {
        if (!sessionStorage[StorageField]) {
          navigate(`${Routes.purchase}/?sku=${sku}`)
          return () => {}
        }

        const plans = await Plans.get(),
          plan = plans.find(plan => plan.sku === sku)
        if (plan) {
          setPlan(plan)
        } else {
          navigate(Routes.plans)
        }
      } catch (error) {
        // TODO: Handle error
      }
    })()
  }, [])

  const cachedData = JSON.parse(sessionStorage[StorageField])

  async function onSubmit() {
    const response = await Plans.createSubscription({
      email: cachedData.payerEmail,
      full_name: `${cachedData.payerFirstName} ${cachedData.payerLastName}`,
      student_first_name: cachedData.studentName,
      student_last_name: "",
      student_phone_number: cachedData.studentPhone,
      student_email: cachedData.studentEmail,
      payer_phone_number: cachedData.payerPhone,
      billing_name: cachedData.cardName ?? "",
      address_1: cachedData.billing1,
      address_2: cachedData.billing2 ?? "",
      city: cachedData.city,
      state: cachedData.state,
      zipcode: Number(cachedData.zipCode),
      sku: sku ?? "",
      dollar_referral_token_id: cachedData.referralCode,
      payment_info: cachedData.paymentInfo,
    })
    if (response.status === "error") {
      setErrorMessage(response.error.message?.flat()[0] ?? Content.defaultError)
    } else {
      delete sessionStorage[StorageField]
      navigate(Routes.purchaseComplete)
    }
  }

  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.reviewPurchase}
      />
      <Header />
      {plan && <PlanDetails plan={plan} editLink={Routes.plans} />}
      <StudentAccountDetails
        data={cachedData}
        editLink={`${Routes.purchase}?sku=${sku}#student-details`}
      />
      <PayerAccountDetails
        data={cachedData}
        editLink={`${Routes.purchase}?sku=${sku}#payer-details`}
      />
      <PaymentDetails
        data={cachedData}
        editLink={`${Routes.purchase}?sku=${sku}#payment-details`}
      />
      <StartPlanSection onSubmit={onSubmit} />
      <PlanSubmissionFail
        message={errorMessage}
        onDismiss={() => setErrorMessage("")}
      />
    </Layout>
  )
}

export default ReviewPurchase
